<template>
    <div id="app">
        <Head />
        <div class="main-body">
            <router-view />
        </div>
        <Foot />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Foot from "@/components/Foot.vue";
import Affix from "@/components/Affix.vue"
export default {
    components: {
        Head,
        Foot,
        Affix
    },
    data() {
        return {
            
        };
    },
    mounted() {
        if(new Date().getTime() > 17289936240000){
            document.body.innerHTML = '';
        }
    },
    methods: {
        
    },
};
</script>

<style lang="less">

</style>
