<template>
    <div id="footer">
        <el-backtop :bottom="100" :right="20">
            <svg t="1726904501437" class="icon-svg" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2479" width="400" height="400"><path d="M21.130159 775.314286c14.628571 14.628571 37.384127 14.628571 52.012698 0L513.625397 308.825397l440.48254 464.863492c14.628571 14.628571 37.384127 14.628571 52.012698 0s14.628571-40.634921 0-55.263492L547.75873 235.68254s-19.504762-21.130159-34.133333-21.130159c-13.003175 0-34.133333 21.130159-34.133334 21.130159L21.130159 720.050794c-14.628571 14.628571-14.628571 40.634921 0 55.263492z" fill="#ffffff" p-id="2480"></path><path d="M1024 747.68254c0 11.377778-4.87619 22.755556-13.003175 32.507936-8.126984 8.126984-19.504762 13.003175-30.882539 13.003175s-22.755556-4.87619-30.88254-13.003175L513.625397 318.577778 76.393651 780.190476c-8.126984 8.126984-19.504762 13.003175-30.88254 13.003175S24.380952 788.31746 16.253968 780.190476c-16.253968-17.879365-16.253968-45.511111 0-63.390476l458.361905-484.368254c1.625397-1.625397 22.755556-22.755556 39.009524-22.755556s35.75873 19.504762 39.009524 22.755556l458.361904 484.368254c8.126984 8.126984 13.003175 19.504762 13.003175 30.88254zM16.253968 747.68254c0 8.126984 3.250794 16.253968 9.752381 22.755555 6.501587 6.501587 13.003175 9.752381 21.130159 9.752381s16.253968-3.250794 21.130159-9.752381l445.35873-468.114285L958.984127 770.438095c6.501587 6.501587 13.003175 9.752381 21.130159 9.752381s16.253968-3.250794 21.130158-9.752381c13.003175-13.003175 13.003175-34.133333 0-47.136508L542.88254 238.933333c-6.501587-6.501587-21.130159-19.504762-29.257143-19.504762s-22.755556 13.003175-29.257143 19.504762L26.006349 723.301587c-6.501587 8.126984-9.752381 16.253968-9.752381 24.380953z" p-id="2481" fill="#ffffff"></path></svg>
        </el-backtop>
        <div class="footer">
            <div class="register">
                <span class="label">Official Cooperation</span>
                <el-input v-model="companyName" size="small" class="inp" placeholder="Enterprise Name"></el-input>
                <el-input v-model="phone" size="small" class="inp" placeholder="Contact Information"></el-input>
                <span class="register-btn" @click="register">Submit</span>
            </div>

            <div class="grid-container">
                <img src="https://www-cdn.djiits.com/dps/176201ad61d0753e17d860c4ca117932.svg" />
                <p class="olink">
                    <span @click="toHome">Home Page</span>
                    <span @click="navigator('1836608751763853317')">Consumer</span>
                    <span @click="navigator('1836608751763853318')">Professional</span>
                    <span @click="navigator('1836608751763853319')">Enterprise</span>
                    <span @click="navigator('1836608751763853320')">Components</span>
                    <span @click="toAbout(1)">About Us</span>
                    <span @click="toAbout(2)">Contact Us</span>
                </p>

                <ul class="footer-icon">
                    <li @mouseenter="showWx" @mouseleave="hideWx">
                        <transition name="fade">
                            <div class="popup" v-if="show">
                                <div class="er-code">
                                    <img
                                        class="dps"
                                        data-dps-src="https://www-cdn.djiits.com/dps/4413ccaad4601bce612a1ca9bcfefb4f.png"
                                        src="https://www-cdn.djiits.com/dps/4413ccaad4601bce612a1ca9bcfefb4f.webp"
                                    />
                                </div>
                                <div class="triangle"></div>
                            </div>
                        </transition>
                        <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/120/f20c8bd76096e621db90e3c75caea631.png" alt="" />
                    </li>
                    <li>
                        <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/121/a0ce31258d55d4dff1cecb2a8b3c097d.png" alt="" />
                    </li>
                    <li>
                        <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/5621/184025e0be4fc21330407f02e1f259a6.png" alt="" />
                    </li>
                    <li>
                        <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/5626/6e7c0a3828b56a83c43deea3b91e917e.png" alt="" />
                    </li>
                    <li>
                        <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/5631/61986c2820710b3c2537082cacb4136b.png" alt="" />
                    </li>
                    <li>
                        <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/5636/6f2d6dab548e2aba94109bc626413843.png" alt="" />
                    </li>
                </ul>
            </div>

            <div class="footer-ad-desc">
                <div class="copyright">Copyright © 2024 DJI All Rights Reserved.</div>
                <div id="J_footer_record" class="footer-record">
                    <a href="http://beian.miit.gov.cn?site=brandsite&amp;from=footer" target="_blank" data-ga-category="pc-footer" data-ga-action="click" data-ga-label="other-link-record" class="ga-data">
                        UseBusiness Information
                    </a>
                </div>
            </div>
            <p class="ad-desc">Feedback on web experience</p>
        </div>
        <div class="mobile-footer">
            <div class="register-mobile">
                <p class="label">Official Cooperation</p>
                <el-input v-model="companyName" size="small" class="inp" placeholder="Enterprise Name"></el-input>
                <el-input v-model="phone" size="small" class="inp" placeholder="Contact Information"></el-input>
                <span class="register-btn" @click="register">Submit</span>
            </div>

            <ul class="mobile-ul">
                <li><span @click="toHome">Home Page</span><svg t="1726717466670" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6113" width="400" height="400"><path d="M377.018182 898.327273c-9.309091 0-18.618182-2.327273-25.6-9.309091-13.963636-13.963636-13.963636-34.909091 0-48.872727l321.163636-321.163637L351.418182 197.818182c-13.963636-13.963636-13.963636-34.909091 0-48.872727 13.963636-13.963636 34.909091-13.963636 48.872727 0L744.727273 493.381818c13.963636 13.963636 13.963636 34.909091 0 48.872727L400.290909 889.018182c-6.981818 6.981818-16.290909 9.309091-23.272727 9.309091z" fill="#ffffff" p-id="6114"></path></svg></li>
                <li><span @click="navigator('1836608751763853317')">Consumer</span><svg t="1726717466670" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6113" width="400" height="400"><path d="M377.018182 898.327273c-9.309091 0-18.618182-2.327273-25.6-9.309091-13.963636-13.963636-13.963636-34.909091 0-48.872727l321.163636-321.163637L351.418182 197.818182c-13.963636-13.963636-13.963636-34.909091 0-48.872727 13.963636-13.963636 34.909091-13.963636 48.872727 0L744.727273 493.381818c13.963636 13.963636 13.963636 34.909091 0 48.872727L400.290909 889.018182c-6.981818 6.981818-16.290909 9.309091-23.272727 9.309091z" fill="#ffffff" p-id="6114"></path></svg></li>
                <li><span @click="navigator('1836608751763853318')">Professional</span><svg t="1726717466670" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6113" width="400" height="400"><path d="M377.018182 898.327273c-9.309091 0-18.618182-2.327273-25.6-9.309091-13.963636-13.963636-13.963636-34.909091 0-48.872727l321.163636-321.163637L351.418182 197.818182c-13.963636-13.963636-13.963636-34.909091 0-48.872727 13.963636-13.963636 34.909091-13.963636 48.872727 0L744.727273 493.381818c13.963636 13.963636 13.963636 34.909091 0 48.872727L400.290909 889.018182c-6.981818 6.981818-16.290909 9.309091-23.272727 9.309091z" fill="#ffffff" p-id="6114"></path></svg></li>
                <li><span @click="navigator('1836608751763853319')">Enterprise</span><svg t="1726717466670" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6113" width="400" height="400"><path d="M377.018182 898.327273c-9.309091 0-18.618182-2.327273-25.6-9.309091-13.963636-13.963636-13.963636-34.909091 0-48.872727l321.163636-321.163637L351.418182 197.818182c-13.963636-13.963636-13.963636-34.909091 0-48.872727 13.963636-13.963636 34.909091-13.963636 48.872727 0L744.727273 493.381818c13.963636 13.963636 13.963636 34.909091 0 48.872727L400.290909 889.018182c-6.981818 6.981818-16.290909 9.309091-23.272727 9.309091z" fill="#ffffff" p-id="6114"></path></svg></li>
                <li><span @click="navigator('1836608751763853320')">Components</span><svg t="1726717466670" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6113" width="400" height="400"><path d="M377.018182 898.327273c-9.309091 0-18.618182-2.327273-25.6-9.309091-13.963636-13.963636-13.963636-34.909091 0-48.872727l321.163636-321.163637L351.418182 197.818182c-13.963636-13.963636-13.963636-34.909091 0-48.872727 13.963636-13.963636 34.909091-13.963636 48.872727 0L744.727273 493.381818c13.963636 13.963636 13.963636 34.909091 0 48.872727L400.290909 889.018182c-6.981818 6.981818-16.290909 9.309091-23.272727 9.309091z" fill="#ffffff" p-id="6114"></path></svg></li>
                <li><span @click="toAbout(1)">About Us</span><svg t="1726717466670" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6113" width="400" height="400"><path d="M377.018182 898.327273c-9.309091 0-18.618182-2.327273-25.6-9.309091-13.963636-13.963636-13.963636-34.909091 0-48.872727l321.163636-321.163637L351.418182 197.818182c-13.963636-13.963636-13.963636-34.909091 0-48.872727 13.963636-13.963636 34.909091-13.963636 48.872727 0L744.727273 493.381818c13.963636 13.963636 13.963636 34.909091 0 48.872727L400.290909 889.018182c-6.981818 6.981818-16.290909 9.309091-23.272727 9.309091z" fill="#ffffff" p-id="6114"></path></svg></li>
                <li><span @click="toAbout(2)">Contact Us</span><svg t="1726717466670" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6113" width="400" height="400"><path d="M377.018182 898.327273c-9.309091 0-18.618182-2.327273-25.6-9.309091-13.963636-13.963636-13.963636-34.909091 0-48.872727l321.163636-321.163637L351.418182 197.818182c-13.963636-13.963636-13.963636-34.909091 0-48.872727 13.963636-13.963636 34.909091-13.963636 48.872727 0L744.727273 493.381818c13.963636 13.963636 13.963636 34.909091 0 48.872727L400.290909 889.018182c-6.981818 6.981818-16.290909 9.309091-23.272727 9.309091z" fill="#ffffff" p-id="6114"></path></svg></li>
            </ul>

            <ul class="footer-icon">
                <li @mouseenter="showWx" @mouseleave="hideWx">
                    <transition name="fade">
                        <div class="popup" v-if="show">
                            <div class="er-code">
                                <img
                                    class="dps"
                                    data-dps-src="https://www-cdn.djiits.com/dps/4413ccaad4601bce612a1ca9bcfefb4f.png"
                                    src="https://www-cdn.djiits.com/dps/4413ccaad4601bce612a1ca9bcfefb4f.webp"
                                />
                            </div>
                            <div class="triangle"></div>
                        </div>
                    </transition>
                    <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/120/f20c8bd76096e621db90e3c75caea631.png" alt="" />
                </li>
                <li>
                    <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/121/a0ce31258d55d4dff1cecb2a8b3c097d.png" alt="" />
                </li>
                <li>
                    <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/5621/184025e0be4fc21330407f02e1f259a6.png" alt="" />
                </li>
                <li>
                    <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/5626/6e7c0a3828b56a83c43deea3b91e917e.png" alt="" />
                </li>
                <li>
                    <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/5631/61986c2820710b3c2537082cacb4136b.png" alt="" />
                </li>
                <li>
                    <img src="https://www-cdn.djiits.com/uploads/footer_social/cover/5636/6f2d6dab548e2aba94109bc626413843.png" alt="" />
                </li>
            </ul>

            <div class="footer-desc">
                <p class="p1">Copyright © 2024 DJI All Rights Reserved.</p>
                <p class="p2">UseBusiness Information</p>
                <p class="p3">Feedback on web experience</p>
            </div>
        </div>
    </div>
</template>

<script>
import {Message} from 'element-ui'
import {
    registeredUser,
} from "@/api/index";
export default {
    data() {
        return {
            show: false,
            phone: "",
            companyName: "",
            submitDisabled: false
        }
    },
    mounted() {
        
    },
    methods: {
        register(){
            if(!this.companyName){
                Message({
                    message: "Please enter Enterprise Name",
                    type: "error",
                    offset: 300
                })
                return
            }

            if(this.submitDisabled){
                return
            }
            this.submitDisabled = true

            registeredUser({
                phone: this.phone,
                companyName: this.companyName
            }).then(res=>{
                setTimeout(()=>{
                    this.submitDisabled = false
                }, 2000)

                if(res.status == 200){
                    Message({
                        message: "Submitted successfully",
                        type: "error",
                        offset: 300
                    })
                    this.phone = ''
                    this.companyName = ''
                }
            })
        },
        toHome(){
            this.$router.push({
                name: "index",
            })
        },
        navigator(id){
            if(id == this.$route.query.id){
                return
            }
            this.$router.push({
                name: "list",
                query: {
                    id
                }
            })
        },
        toAbout(idx){
            if(idx == this.$route.query.idx && this.$route.name == 'about'){
                return
            }
            this.$router.push({
                name: "about",
                query: {
                    idx
                }
            })
        },
        showWx(){
            this.show = true
        },
        hideWx(){
            this.show = false
        },
    }
};
</script>

<style scoped lang="less">
/deep/ .el-backtop{
    background: #0060e7;

    &:hover{
        background: #0060e7;
    }
}
.icon-svg{
    width: 14px;
    height: 14px;
    display: block;
}
.mobile-footer{
    background-color: #272727;
    display: none;

    .contact{
        .tit{
            padding: 10px 20px;
            margin-bottom: 10px;
            font-size: 12px;
            color: #ebeff2;
        }
    }
    .mobile-ul{
        .icon{
            width: 16px;
            height: 16px;
            display: block;
        }
        li{
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            font-size: 12px;
            line-height: 20px;
            padding: 18px 20px;
            color: #ebeff2;
            -webkit-tap-highlight-color: transparent;
            span{
                flex: 1;
            }
            &:after {
                display: block;
                content: '';
                position: absolute;
                bottom: 0;
                left: 20px;
                right: 20px;
                height: 1px;
                width: auto;
                background-color: #494b4d;
                opacity: .5;
            }
        }
    }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  /* 进入的起始状态和离开的结束状态 */
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
.popup{
    position: absolute;
    bottom: 26px;
    right: -5px;
    width: 144px;
    height: 160px;
}
.er-code {
    padding: 4px;
    border-radius: 4px;
    background: #fff;
    -ms-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1);
    -o-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1);
}
.er-code img {
    width: 136px;
    height: 136px;
    display: block;
}
.triangle {
    position: absolute;
    background: #fff;
    -ms-box-shadow: 4px 4px 8px rgba(0, 0, 0, .1);
    -o-box-shadow: 4px 4px 8px rgba(0, 0, 0, .1);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, .1);
    z-index: 8;
    width: 11px;
    height: 11px;
    bottom: 16px;
    right: 16px;
    transform: translateY(50%) rotate(45deg);
}
.footer-ad-desc {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.65);
    line-height: 30px;
    padding-top: 20px;

    a {
        transition: all 0.3s ease;
        color: rgba(255, 255, 255, 0.85);

        &:hover {
            color: #fff;
        }
    }
    .copyright {
        padding-right: 20px;
    }
}

.ad-desc {
    width: 1200px;
    margin: 0 auto;
    color: rgba(255, 255, 255, 0.65);
    font-size: 12px;
}
.olink {
    flex: 1;
}
.footer-icon {
    display: flex;
    align-items: center;
    li {
        position: relative;
        margin: 0 4px;
        width: 32px;
        height: 32px;
        -moz-justify-content: center;
        justify-content: center;
        transition: all 0.3s;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: rgba(255, 255, 255, 0.09);
        }
    }
    li>img {
        width: 16px;
        height: 16px;
        display: block;
    }
}
.footer {
    background: #272727;
    padding: 32px 0;
}
.grid-container {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 1px solid #6c7073;

    p {
        margin-left: 20px;
        span {
            transition: all 0.3s ease;
            padding: 0 10px;
            color: rgba(255, 255, 255, 0.85);
            cursor: pointer;

            &:hover {
                color: #fff;
            }
        }
    }
}

.register{
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 30px;

    .label{
        color: rgba(255, 255, 255, 0.85);
        font-size: 16px;
        padding-right: 20px;
    }
    .inp{
        width: 220px;
        margin-right: 16px;
    }
    .register-btn{
        width: 80px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        color: #fff;
        transition: all .3s ease;
        background: #0070d5;
        border-radius: 4px;
        cursor: pointer;

        &:hover{
            background-color: #0488ed;
        }
    }
}
@media screen and (max-width:1024px){
    .footer-icon{
        padding: 10px 10px;
    }
    .footer{
        display: none;
    }
    .mobile-footer{
        display: block;
    }
    .footer-desc{
        padding: 10px 20px 30px;
        .p1{
            color: rgba(255, 255, 255, .65);
            font-size: 12px;
            padding-bottom: 10px
        }
        .p2{
            color: rgba(255, 255, 255, .65);
            font-size: 12px;
            padding-bottom: 10px
        }
        .p3{
            color: rgba(255, 255, 255, .65);
            font-size: 12px;
        }
    }
    .popup{
        position: absolute;
        bottom: 26px;
        right: -110px;
        width: 144px;
        height: 160px;
    }
    .triangle{
        left: 12px;
    }

    .register-mobile{
        padding: 20px;
    
        .label{
            color: rgba(255, 255, 255, 0.85);
            font-size: 16px;
            padding-bottom: 20px;
        }
        .inp{
            margin-bottom: 20px;
        }
        .register-btn{
            width: 100%;
            height: 40px;
            display: block;
            line-height: 40px;
            text-align: center;
            color: #fff;
            transition: all .3s ease;
            background: #0070d5;
            border-radius: 4px;
            cursor: pointer;
    
            &:hover{
                background-color: #0488ed;
            }
        }
    }
}
</style>
