import request from '@/api/axios';

export const getProduct = (params) => request({
    url: 'https://www.yinstars.com:5001/productid',
    method: 'get',
    params
})

export const getProductDetail = (params) => request({
    url: 'https://www.yinstars.com:5001/productdetail',
    method: 'get',
    params
})

export const registeredUser = (data) => request({
    url: 'https://www.yinstars.com:5001/registeredUser',
    method: 'post',
    data
})

export const getUserpage = (params) => request({
    url: 'https://www.yinstars.com:5001/page',
    method: 'get',
    params
})

export const insertProduct = (data) => request({
    url: 'https://www.yinstars.com:5001/insertProduct',
    method: 'post',
    data
})
