import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
		path: '/',
		redirect: '/index',
	},
    {
        path: "/404",
        component: (resolve) => require(["../views/404"], resolve),
    },
    { path: "*", redirect: "/404"},
    {
        path: '/index',
        name: 'index',
        component: () => import(/* webpackChunkName: "index" */ '../views/index.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
    },
    {
        path: '/list',
        name: 'list',
        component: () => import(/* webpackChunkName: "list" */ '../views/list.vue')
    },
    {
        path: '/goods',
        name: 'goods',
        component: () => import(/* webpackChunkName: "goods" */ '../views/goods.vue')
    },
    {
        path: '/register-user',
        name: 'register-user',
        component: () => import(/* webpackChunkName: "goods" */ '../views/registerUser.vue')
    },
    {
        path: '/add-goods',
        name: 'add-goods',
        component: () => import(/* webpackChunkName: "goods" */ '../views/addGoods.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
