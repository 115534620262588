<template>
    <div>
        <div :class="['header', className, goodsClass]">
            <div class="navbar-container">
                <img v-if="className || goodsClass" src="https://www-cdn.djiits.com/assets/images/v3/logo-black-5069d1009cdf1d5f70ef519b57886731.png" class="logo" />
                <img v-else src="https://www-cdn.djiits.com/assets/images/v3/logo-white-5d7bb59954f3b2279536a04907b6afb5.png" class="logo" />
                <ul class="nav">
                    <li @click="toHome">Home Page</li>
                    <li @click="navigator('1836608751763853317')">Consumer</li>
                    <li @click="navigator('1836608751763853318')">Professional</li>
                    <li @click="navigator('1836608751763853319')">Enterprise</li>
                    <li @click="navigator('1836608751763853320')">Components</li>
                    <li @click="toAbout(1)">About Us</li>
                    <li @click="toAbout(2)">Contact Us</li>
                </ul>
                <!-- <span class="btns">商城</span> -->
            </div>
        </div>
        <div :class="['mobile-header', opened ? 'header-opened' : '', openClassName, mobileClassName, goodsClass]">
            <div class="navbar-header">
                <a href="javascript:;" @click="openNav" aria-label="navbar-control" class="navbar-control">
                    <span class="control-icon"></span>
                    <span class="control-icon"></span>
                    <span class="control-icon"></span>
                </a>
                <div class="logo-box">
                    <img v-if="!mobileClassName && !openClassName && !goodsClass" src="https://www-cdn.djiits.com/assets/images/v3/logo-white-5d7bb59954f3b2279536a04907b6afb5.png" class="logo" />
                    <img v-else src="https://www-cdn.djiits.com/assets/images/v3/logo-black-5069d1009cdf1d5f70ef519b57886731.png" class="logo" />
                </div>
                <span class="sc"></span>
            </div>
            <div class="navbar-content">
                <ul class="navbar-group-list">
                    <li class="navbar-group navbar-group-animate" @click="toHome">
                        <span class="navbar-group-head">Home Page</span>
                    </li>
                    <li class="navbar-group navbar-group-animate" @click="navigator('1836608751763853317')">
                        <span class="navbar-group-head">Consumer</span>
                    </li>
                    <li class="navbar-group navbar-group-animate" @click="navigator('1836608751763853318')">
                        <span class="navbar-group-head">Professional</span>
                    </li>
                    <li class="navbar-group navbar-group-animate" @click="navigator('1836608751763853319')">
                        <span class="navbar-group-head">Enterprise</span>
                    </li>
                    <li class="navbar-group navbar-group-animate" @click="navigator('1836608751763853320')">
                        <span class="navbar-group-head">Components</span>
                    </li>
                    <li class="navbar-group navbar-group-animate" @click="toAbout(1)">
                        <span class="navbar-group-head">About Us</span>
                    </li>
                    <li class="navbar-group navbar-group-animate" @click="toAbout(2)">
                        <span class="navbar-group-head">Contact Us</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            openClassName: "",
            mobileClassName: "",
            className: '',
            goodsClass: "",
            opened: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    watch: {
        $route(data) {
            if(data.name == 'list' || data.name == 'register-user' || data.name == 'goods' || data.name == 'add-goods'){
                if(this.isMobile()){
                    this.goodsClass = 'mobile-affix'
                }else{
                    this.goodsClass = 'affix'
                }
            }else{
                this.goodsClass = ''
            }
        },
    },
    methods: {
        toHome(){
            this.opened = false
            document.documentElement.className = ''
            window.scrollTo({
                top: 0,
                left: 0
            })

            if(this.$route.name == 'index'){
                return
            }
            
            this.$router.push({
                name: "index",
            })
        },
        navigator(id){
            this.opened = false
            document.documentElement.className = ''
            window.scrollTo({
                top: 0,
                left: 0
            })

            if(id == this.$route.query.id){
                return
            }
            this.$router.push({
                name: "list",
                query: {
                    id
                }
            })
        },
        toAbout(idx){
            this.opened = false
            document.documentElement.className = ''
            window.scrollTo({
                top: 0,
                left: 0
            })

            if(idx == this.$route.query.idx && this.$route.name == 'about'){
                return
            }
            this.$router.push({
                name: "about",
                query: {
                    idx
                }
            })
        },
        openNav(){
            this.opened = !this.opened
            if(this.opened){
                this.openClassName = 'mobile-fixed';
                document.documentElement.className = 'html-opened'
            }else{
                this.openClassName = '';
                document.documentElement.className = ''
            }
        },
        isMobile() {
            return (
                typeof window.orientation !== "undefined" || // 判断是否存在window.orientation属性，此属性在移动设备上一般存在
                navigator.userAgent.indexOf('IEMobile') !== -1 || // 判断是否为Windows Phone
                navigator.userAgent.indexOf('iPhone') !== -1 || // 判断是否为iPhone
                navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1 || // 判断是否为Android手机
                navigator.userAgent.indexOf('BlackBerry') !== -1 || // 判断是否为BlackBerry
                navigator.userAgent.indexOf('Opera Mini') !== -1 // 判断是否为Opera Mini浏览器
            );
        },
        handleScroll() {
            if(this.isMobile()){
                const scrollTop = this.getScroll(window, true); // handle setting offset
                if (scrollTop > 0) {
                    this.mobileClassName = 'mobile-affix';
                } else {
                    this.mobileClassName = '';
                }
            }else{
                const scrollTop = this.getScroll(window, true); // handle setting offset
                if (scrollTop > 90) {
                    this.className = 'affix';
                } else {
                    this.className = '';
                }
            }
        },
        getScroll(w, top) {
            let ret = w[`page${top ? 'Y' : 'X'}Offset`];
            const method = `scroll${top ? 'Top' : 'Left'}`;
            if (typeof ret !== 'number') {
                const d = w.document;
                // ie6,7,8 standard mode
                ret = d.documentElement[method];
                if (typeof ret !== 'number') {
                    // quirks mode
                    ret = d.body[method];
                }
            }
            return ret;
        },
        getOffset(element) {
            const rect = element.getBoundingClientRect();
            const body = document.body;
            const clientTop = element.clientTop || body.clientTop || 0;
            const clientLeft = element.clientLeft || body.clientLeft || 0;
            // const clientHeight = element.clientHeight || 0;
            const scrollTop = this.getScroll(window, true);
            const scrollLeft = this.getScroll(window);
            return {
                top: rect.bottom + scrollTop - clientTop - this.affixedClientHeight,
                left: rect.left + scrollLeft - clientLeft,
            };
        },
    },
};
</script>

<style scoped lang="less">
.mobile-header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 213;
    display: none;
}
.btns {
    transition: background-color 0.3s ease;
    word-break: break-all;
    max-width: initial;
    background-color: #0070d5;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYuODc0LjMxM2MuOTY3IDAgMS43NS43ODMgMS43NSAxLjc1djEuMzEyaDIuNjAybC40NiA4LjMxNi0uODczLS4wMDctLjQxNS03LjQzNEgxLjYwMWwtLjQxNSA3LjQzNC0uODczLjAwNy40Ni04LjMxNmgyLjYwMVYyLjA2MmMwLS45NjYuNzg0LTEuNzUgMS43NS0xLjc1aDEuNzV6bTEuNzUgNS4yNjN2Mi42MTZjMCAuOTU1LS43NjggMS43My0xLjcyIDEuNzQ1aC0xLjc4YTEuNzQ4IDEuNzQ4IDAgMCAxLTEuNzUtMS43MTZWNS41NzZoLjAxNWEuODc1Ljg3NSAwIDAgMSAuODYuODZ2MS43NTZjMCAuNDc2LjM4NC44NjMuODYuODdoMS43NjVhLjg3My44NzMgMCAwIDAgLjg3NS0uODU2VjYuNDUyYzAtLjQ4LjM4NC0uODY4Ljg2LS44NzZoLjAxNXptLTEuNzUtNC4zODhoLTEuNzVhLjg3NS44NzUgMCAwIDAtLjg3NS44NzV2MS4zMTJoMy41VjIuMDYyYS44NzUuODc1IDAgMCAwLS44NzUtLjg3NHoiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjg1Ii8+PC9zdmc+) !important;
    background-size: 12px 12px !important;
    background-position: 13px 50% !important;
    background-repeat: no-repeat !important;
    border-radius: 88rem;
    border: none;
    font-size: 14px;
    letter-spacing: -0.02em;
    display: block;
    height: 30px;
    color: #fff;
    line-height: 14px;
    padding: 8px 16px 8px 34px;
    cursor: pointer;

    &:hover {
        background-color: #2490e3;
    }
}
.header {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 64px;
    border: 0;
    margin: auto;
    -webkit-font-smoothing: antialiased;
    z-index: 1001;
}
.affix {
    position: fixed;
    z-index: 999;
    background: #fff;

    .nav {
        li {
            cursor: pointer;
            color: rgba(0, 0, 0, 0.85);
            &:hover {
                color: rgba(0, 0, 0, 1);
            }
        }
    }
}
.mobile-affix{
    position: fixed;
    z-index: 999;
    background: #fff;

    .nav {
        li {
            color: rgba(0, 0, 0, 0.85);
            &:hover {
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }
}
.mobile-fixed{
    position: fixed;
    z-index: 999;
    background: #fff;

    .nav {
        li {
            color: rgba(0, 0, 0, 0.85);
            &:hover {
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }
}
.navbar-container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 64px;
}
.logo {
    width: 45px;
    height: 26px;
    display: block;
}
.nav {
    flex: 1;
    margin-left: 60px;
    display: flex;
    align-items: center;
    li {
        font-size: 14px;
        margin-right: 30px;
        color: #fff;
        cursor: pointer;

        &:hover {
            color: rgba(255, 255, 255, .65);
        }
    }
}

@media screen and (max-width: 1024px) {
    .header {
        display: none;
    }
    .mobile-header{
        display: block;
    }
    .navbar-header{
        display: flex;
        align-items: center;
    }
    a.navbar-control {
        padding: 13px 16px;
        -webkit-tap-highlight-color: transparent;
    }
    .control-icon {
        display: block;
        padding-top: 3px;
        padding-bottom: 3px;
        transition: all .4s ease;
    }
    .navbar-control .control-icon:before{
        background-color: #fff;
    }
    .control-icon:before {
        content: '';
        display: block;
        height: 2px;
        width: 22px;
        background-color: #303233;
        border-top-right-radius: 2px;
        border-bottom-left-radius: 2px;
        transition: transform .4s ease .2s, background-color .5s ease 0s;
    }
    .logo-box{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sc{
        display: inline-flex;
        transition: none;
        margin-right: 12px;
        word-break: keep-all;
        background-color: initial;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYuODc0LjMxM2MuOTY3IDAgMS43NS43ODMgMS43NSAxLjc1djEuMzEyaDIuNjAybC40NiA4LjMxNi0uODczLS4wMDctLjQxNS03LjQzNEgxLjYwMWwtLjQxNSA3LjQzNC0uODczLjAwNy40Ni04LjMxNmgyLjYwMVYyLjA2MmMwLS45NjYuNzg0LTEuNzUgMS43NS0xLjc1aDEuNzV6bTEuNzUgNS4yNjN2Mi42MTZjMCAuOTU1LS43NjggMS43My0xLjcyIDEuNzQ1aC0xLjc4YTEuNzQ4IDEuNzQ4IDAgMCAxLTEuNzUtMS43MTZWNS41NzZoLjAxNWEuODc1Ljg3NSAwIDAgMSAuODYuODZ2MS43NTZjMCAuNDc2LjM4NC44NjMuODYuODdoMS43NjVhLjg3My44NzMgMCAwIDAgLjg3NS0uODU2VjYuNDUyYzAtLjQ4LjM4NC0uODY4Ljg2LS44NzZoLjAxNXptLTEuNzUtNC4zODhoLTEuNzVhLjg3NS44NzUgMCAwIDAtLjg3NS44NzV2MS4zMTJoMy41VjIuMDYyYS44NzUuODc1IDAgMCAwLS44NzUtLjg3NHoiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjg1Ii8+PC9zdmc+) !important;
        background-repeat: no-repeat;
        border: none !important;
        background-size: 24px 24px;
        background-position: center;
        width: 32px;
        padding: 0;
        align-items: center;
        height: 32px;
        border-radius: 88rem;
        color: rgba(0, 0, 0, .85);
        font-weight: 400;
        font-size: 14px;
        letter-spacing: -.02em;
    }

    .navbar-content {
        transition: all .6s ease;
        position: relative;
        z-index: 9;
        background-color: #fff;
        height: 0;
        overflow-x: hidden;
        overflow-y: scroll;

        li{
            transition: all .3s ease;
        }
    }

    .header-opened .control-icon:nth-child(1){
        transform: translateY(8px);
    }
    .header-opened .control-icon:nth-child(2) {
        opacity: 0;
    }
    .header-opened .control-icon:nth-child(3) {
        transform: translateY(-8px);
    }
    .header-opened .navbar-header {
        background-color: #fff;
    }
    .header-opened .navbar-content {
        background-color: #fff;
        height: calc(100vh - 50px);
    }
    .header-opened .control-icon:nth-child(1):before {
        transform: rotate(45deg);
    }
    .header-opened .control-icon:nth-child(3):before {
        transform: rotate(-45deg);
    }

    .mobile-affix,.mobile-fixed{
        .navbar-control .control-icon:before{
            background-color: #000;
        }
        .sc{
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYuODc0LjMxM2MuOTY3IDAgMS43NS43ODMgMS43NSAxLjc1djEuMzEyaDIuNjAybC40NiA4LjMxNi0uODczLS4wMDctLjQxNS03LjQzNEgxLjYwMWwtLjQxNSA3LjQzNC0uODczLjAwNy40Ni04LjMxNmgyLjYwMVYyLjA2MmMwLS45NjYuNzg0LTEuNzUgMS43NS0xLjc1aDEuNzV6bTEuNzUgNS4yNjN2Mi42MTZjMCAuOTU1LS43NjggMS43My0xLjcyIDEuNzQ1aC0xLjc4YTEuNzQ4IDEuNzQ4IDAgMCAxLTEuNzUtMS43MTZWNS41NzZoLjAxNWEuODc1Ljg3NSAwIDAgMSAuODYuODZ2MS43NTZjMCAuNDc2LjM4NC44NjMuODYuODdoMS43NjVhLjg3My44NzMgMCAwIDAgLjg3NS0uODU2VjYuNDUyYzAtLjQ4LjM4NC0uODY4Ljg2LS44NzZoLjAxNXptLTEuNzUtNC4zODhoLTEuNzVhLjg3NS44NzUgMCAwIDAtLjg3NS44NzV2MS4zMTJoMy41VjIuMDYyYS44NzUuODc1IDAgMCAwLS44NzUtLjg3NHoiIGZpbGw9IiMwMDAiIGZpbGwtb3BhY2l0eT0iLjg1Ii8+PC9zdmc+) !important;
            background-repeat: no-repeat;
        }
    }
    .navbar-group-head{
        transform: translateY(-16px);
        opacity: 0;
        transition: all .3s ease;
        will-change: transform;
    }
    .navbar-group-list {
        height: calc(100vh - 50px);
        overflow-y: scroll;
        padding: 8px 0 64px;
        transition: opacity .3s ease;
        opacity: 0;

        li{
            padding: 16px 20px 16px 16px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -.02em;
            display: flex;
            justify-content: space-between;
            position: relative;
        }
    }
    .header-opened .navbar-content .navbar-group-list {
        opacity: 1;
    }

    .header-opened .navbar-group-head {
        transform: translateY(0) translateZ(0);
        opacity: 1;
    }
}
</style>
