import axios from 'axios';
import {Message} from 'element-ui'

//默认超时时间
axios.defaults.timeout = 30000;
//返回其他状态码
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500;
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = false;

//http request拦截
axios.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error)
});

axios.interceptors.response.use(res => {
    if(res.status != 200){
        Message({
            message: res.data.message,
            type: "error",
            offset: 300
        })
    }
    return res.data;
}, error => {
    return Promise.reject(new Error(error));
});

export default axios;
