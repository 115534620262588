import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
Vue.use(ElementUI)

import '@/assets/reset.less';
import '@/assets/public.less';

const i18n = new VueI18n({
    locale: 'zh-CN'
});

i18n.setLocaleMessage('zh-CN',Object.assign({
    text: "菜狗"
}));

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')
